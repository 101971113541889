<template>
  <div id="app">
    <first_page/>
    <footerComponent/>
  </div>
</template>

<script>

import first_page from "@/components/first_page.vue";
import footerComponent from "@/components/FooterComponent.vue";

export default {
  name: 'App',
  components: {
    first_page,
    footerComponent
  }
}
</script>

<style>
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  outline: 0;
  //font-family: 'Helvetica Neue', Helvetica, 'PingFang SC', 'Hiragino Sans GB',
  //'Microsoft YaHei', '微软雅黑', Arial, sans-serif;
}
#app {
  width: 100%;
  overflow-x: hidden;
}
</style>
