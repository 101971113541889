
<template>
  <div>
    <header>
      <h2 class="logo">PersonalPage</h2>
      <nav class="navigation">
        <a href="#" class="active">Home</a>
        <a href="#">About</a>
        <a href="#">Person</a>
        <a href="#">Portfolio</a>
      </nav>
    </header>

    <section class="parallax">
      <img src="@/assets/hill1.png" id="hill1" ref="hill1" />
      <img src="@/assets/hill2.png" id="hill2" ref="hill2" />
      <img src="@/assets/hill3.png" id="hill3" ref="hill3" />
      <img src="@/assets/hill4.png" id="hill4" ref="hill4" />
      <img src="@/assets/hill5.png" id="hill5" ref="hill5" />
      <img src="@/assets/tree.png" id="tree" ref="tree" />
      <div id="text" ref="text" style="margin-top: 0">
        <h2 style="font-size: 5em">HELLO WORLD</h2>
<!--        <br>-->
        <h5 style="font-size: 20px">/*这里是杨怡昊的个人主页*/</h5>
      </div>

      <img src="@/assets/leaf.png" id="leaf" ref="leaf" />
      <img src="@/assets/plant.png" id="plant" ref="plant" />
    </section>

    <section class="sec">
      <h2>Path through the World !</h2>
      <p>
        As a fourth-year college student who's already secured a promising job offer, my foray into the tech industry
        spans a relatively modest three years, during which my blog has stood as a testament to my deep-seated curiosity
        for the eccentric and enthralling facets of technology. This website, a labor of love for nearly a few year,
        represents countless hours of dedication and passion. While the practicality of my explorations may seem
        ambiguous, I hold a steadfast belief that even the seemingly whimsical and trivial pursuits may unveil
        profound significance in the tapestry of the future.<br /><br />

        The swift cadence of the world's evolution never fails to leave me in awe. At times, it feels as though the world
        is sprinting ahead, leaving me to marvel at its pace rather than propelling myself forward. Envisioning the
        future in my earlier years seemed like a whimsical journey, yet the reality of stepping into what I once
        deemed as the future is a staggering revelation, far surpassing my youthful imaginings.<br /><br />

        This realization strikes a chord—our future is not just imminent but tangible. The fantastical narratives
        that once entranced my childhood imagination now pale in comparison to the breathtaking technological marvels
        of today. However, what captivates me most is the trajectory I am carving. As I navigate through the vast
        landscape of knowledge, I've had the privilege of crossing paths with luminaries and pioneers whose
        contributions have shaped the vast universe of computing into what it is today—an expanse that has burgeoned
        exponentially over the last decade.<br /><br />

        While I might find myself trailing in this swiftly changing epoch, my aspiration remains resolute—to
        bear witness to the avant-garde innovations and pioneering technologies that epitomize the leading edge
        of the computer world. This unwavering aspiration is my guiding star as I traverse this ever-evolving
        terrain, where every bend reveals new possibilities and unforeseen horizons waiting to be explored.<br /><br />

        In the embrace of this world, I find myself enchanted by its boundless beauty. From the vibrant hues of dawn to
        the tranquil whispers of twilight, each moment holds a tale woven with wonder. The gentle rustle of leaves in
        the breeze, the symphony of raindrops painting melodies on my windowpane – every sensation, every sight, fuels
        an eternal flame of adoration within me. With every step upon this earth, I am immersed in the grandeur of
        nature's canvas, where each mountain peak and winding stream tells a story of resilience and grace. It's
        within this intricate tapestry that my heart finds solace, and in its intricacies, I whisper to the universe,
        'I am in love with this world.'<br /><br />
      </p>
    </section>
  </div>
</template>

<script>
export default {
  name: 'ParallaxComponent',
  mounted() {
    const text = this.$refs.text;
    const leaf = this.$refs.leaf;
    const hill1 = this.$refs.hill1;
    const hill4 = this.$refs.hill4;
    const hill5 = this.$refs.hill5;

    window.addEventListener('scroll', () => {
      const value = window.scrollY;

      text.style.marginTop = value * 1.5 + 'px';
      leaf.style.top = value * -1.5 + 'px';
      leaf.style.left = value * 1.5 + 'px';
      hill5.style.left = value * 1.5 + 'px';
      hill4.style.left = value * -1.5 + 'px';
      hill1.style.top = value * 0.5 + 'px';
    });
  }
};
</script>


<style scoped>
/*
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700;800;900&display=swap');
*/

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Poppins', sans-serif;
}

::selection {
  background: rgba(0, 0, 0, .2);
}

body {
  background: #f9f9f9;
  min-height: 100vh;
  overflow-x: hidden;
}

header {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  padding: 30px 100px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  z-index: 100;
}

.logo {
  font-size: 2em;
  color: #359381;
  pointer-events: none;
  margin-right: 270px;
}

.navigation a {
  text-decoration: none;
  color: #359381;
  padding: 6px 15px;
  border-radius: 20px;
  margin: 0 10px;
  font-weight: 600;
}

.navigation a:hover,
.navigation a.active {
  background: #359381;
  color: #fff;
}

.parallax {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

#text {
  position: absolute;
  //font-size: 5em;

  color: #fff;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, .2);

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  //height: 100vh;
  margin: 0;
}

.parallax img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  pointer-events: none;
}

.sec {
  position: relative;
  background: #003329;
  padding: 100px;

}

.sec h2 {
  font-size: 3em;
  color: #fff;
  margin-bottom: 10px;
}

.sec p {
  font-size: 1.3em;
  color: #fff;
  font-weight: 300;
  font-family: Abyssinica SIL, serif;
}
</style>